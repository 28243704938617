import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import Modal from 'react-bootstrap/Modal';
import { removeVaultCart } from '../../../apiCall/vault'

const OrderPaymentCancel = () => {
    
    const [error, setErrors] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const params = useParams();

    useEffect(() => {
        goTop();
        init();
    }, []);

    const init = () => {
        const summaryId = params.summaryId;

        setShowSpinner(true);
        removeVaultCart(summaryId).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors('Order has been cancelled!');
                setShowConfirmation(true);
            } 
            else {
                setShowSpinner(false);
                setErrors('Error while cancelling order. ' + res.message);
                setShowConfirmation(true);
            }
        })
    }

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <CustomerLayout>
            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="font-size-16">The Ossuary - Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body  className="font-size-16">{error}</Modal.Body>
                <Modal.Footer>
                    <Link className="btn bg-color-primary" to="/pages/secured/user/my-purchases">OK</Link>
                </Modal.Footer>
            </Modal>
        </CustomerLayout>
    );
};

export default OrderPaymentCancel;
import React, {useState, useEffect } from 'react';
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import BuildingSelection from '../../../components/BuildingSelection';
import Spinner from '../../../components/Spinner';
import { getBuilding } from '../../../apiCall/building';

const BuildingAdmin = () => {

    const [building, setBuilding] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setErrors] = useState("");

    useEffect(() => {
        getList();
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const getList = () => {
        setShowSpinner(true);
        getBuilding().then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors("");
                setBuilding(res.dataDictionary.data);
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };
    
    return (
        <CustomerLayout>
            <main>
                { showSpinner && <Spinner /> }
                <section className="reservation-section">
                    { error && <div className="row mb-12">
                        <div className="col-md">
                            <h3 id="warning-message" className="err-msg">{ error }</h3>
                        </div>
                    </div> }
                    <BuildingSelection data={building} />
                </section>
                
            </main>
        </CustomerLayout>
    );
};

export default BuildingAdmin;
import React from 'react';
import Layout from '../components/Layouts/Layout'
import UnderConstruction from '../components/UnderConstruction'

const afterSales = () => {
    return (
        <Layout>
            <main>
                <div className="container">
                    <div className="row mt-160 mb-40">
                        <div class="col-md">
                        <img class="feature__img" src="https://res.cloudinary.com/fsb-land-holding/image/upload/v1664105583/project01/after-sale/afterSale_rrzqqd.png" alt="" />
                        </div>
                    </div>
                    <div className="row mt-40 mb-20">
                        <div className="col-md text-center">
                            <h1 className="text-color-secondary font-open-sans font-size-38 bold">Smart Columbarium Technology</h1>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md text-center">
                            <h1 className="text-color-primary font-mont font-size-24 bold">THE FIRST IN THE WORLD, COMING SOON!</h1>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};


export default afterSales;
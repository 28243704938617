import { API } from '../config'
import { isAuthenticated } from '../apiCall/auth'

export const getOrderPreview = (summaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/get-order-preview-detail/${summaryId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getOrderDetailContinue = (summaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/get-order-detail-cart-continue/${summaryId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const updateOrderStatus = (summaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/update-order-status`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(summaryId)
    })
    .then(response => {
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const updateOrderPaymentOption = (request) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/update-order-payment-option`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(request)
    })
    .then(response => {
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const submitOrder = (request) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/submit-order`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const validateUpdateOrderStatus = (request) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/validate-update-order-status`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getPurchase = () => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/get-my-purchases`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getPurchaseList = (request) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/get-purchases`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getAllPurchaseList = (request) => {
    
    const token = isAuthenticated().token;

    return fetch(`${API}/cart/get-all-purchases`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getAllPurchaseDetailList = (summaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/get-all-purchase-detail/${summaryId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getOrderSummaryDetail = (summaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/get-order-summary-detail/${summaryId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const saveSummaryDetails = (request) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/save-summary-detail`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const resendPurchaseForm = (request) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/resend-purchase-form`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const saveResendPurchaseForm = (request) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/save-resend-purchase-form`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getOrderSummaryCustomerDetails = (orderSummaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/cart/get-order-summary-customer-details/${orderSummaryId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}
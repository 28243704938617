import { API } from '../config'
import { isAuthenticated } from '../apiCall/auth'

export const getCustomerDetail = (summaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/customer/profile/detail`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const updateCustomerDetail = (request) => {
    
    const token = isAuthenticated().token;
    return fetch(`${API}/customer/profile/update`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(request)
    })
    .then(response => {
        
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}
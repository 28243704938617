import { API } from '../config'
import { isAuthenticated } from '../apiCall/auth'

export const exportAllPurchaseList = (request) => {
    
    const token = isAuthenticated().token;

    

    return fetch(`${API}/export/get-all-purchases`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}
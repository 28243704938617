import React, { useEffect } from 'react';
import Layout from '../components/Layouts/Layout'
import UnderConstruction from '../components/UnderConstruction'

const Contact = () => {

    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Layout>
            <main>
                <div className="container">
                    <div className="row mt-160 mb-20">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">Contact Us</h1>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div class="col-md">
                        <img class="feature__img" src="https://res.cloudinary.com/fsb-land-holding/image/upload/v1664933759/project01/contactus/contactus_c4vo4s.jpg" alt="" />
                        </div>
                        <div className="col-md">
                            <p className="paragraph mt-20">
                            <ul className="footer__list">
                                <li className="footer__contact">+63(32)5056937</li>
                                <li className="footer__contact">+63(32)5115141</li>
                                <li className="footer__contact">+639177010516</li>
                                <li className="footer__contact">ossuarysales.inquiry@fsbland.com</li>
                                <li className="footer__contact">
                                                                <a className="footer__contact--icon" href="https://www.facebook.com/fsbland">FSB Facebook Page</a>
                                </li>
                                <li className="footer__contact">Unit 4 Plaza Margarita, Cebu South National Highway, Barangay Linao Lipata, Minglanilla Cebu 6046</li>
                                <li className="footer__contact">The Ossuary @ Kahangan Estate DHSUD LS-R07-22-076</li>
                                <li className="footer__contact">Developer: FSB Land Holdings</li>
                            </ul>
                            </p>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md">
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    <iframe className="gmap_iframe" 
                                            width="100%" 
                                            frameborder="0" 
                                            scrolling="no" 
                                            marginheight="0" 
                                            marginwidth="0" 
                                            src="https://maps.google.com/maps?width=1000&amp;height=400&amp;hl=en&amp;q=Unit 4 Plaza Margarita, Cebu South National Highway, Barangay Linao Lipata, Minglanilla Cebu 6046&amp;t=p&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </main>
        </Layout>
    );
};


export default Contact;
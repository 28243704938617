import React, { Fragment, useState, useEffect } from 'react';

const PillarSelectionF2 = ({vault, selectedVault, setSelectedVault}) => {

    const header = [
        {
            column: ["08", "07", "06", "05"]
        }
    ];

    const header2 = [
        {
            column: ["04", "03", "02", "01"]
        }
    ];

    const [vaultData, updateVaultData] = useState([]);

    useEffect(() => {
        updateVaultData(vault)
    }, [vault]);

    const onClickVault = (row, col, vault) => {
        let newArr = [...vaultData];
        if(vault.colStatusCode === 1) {
            newArr[row].pillarCols[col].colStatusCode = 8;
            setSelectedVault(selectedVault.concat(vault));
        } else if (vault.colStatusCode === 8) {
            newArr[row].pillarCols[col].colStatusCode = 1;
            setSelectedVault(selectedVault.filter(item => item.colId !== vault.colId))
            updateVaultData(newArr);
        }
    }

    return (
        <Fragment>
            <table className="grid table table-header-rotated vault-table">
                <thead>
                    <tr>
                        <th></th>
                        {
                            header.map(col =>
                                col.column.map((col, idx) =>
                                    <th className="grid-column-header" key={idx} ><div><span>{col}</span></div></th>
                                )
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    { 
                        vaultData && vaultData.map((row, idxRow) => 
                            <tr key={idxRow}>
                                <th className="grid-row-header">{row.pillarLevel}</th>
                                {   
                                    row.pillarCols.slice(0, 4) && row.pillarCols.slice(0, 4).map((col, idxCol) => 
                                        <td 
                                            key={idxCol} 
                                            onDoubleClick = {e => onClickVault(idxRow, idxCol, col)}
                                            className={
                                                    (col.colStatusCode === 1) ? "grid-row-body available" : 
                                                    (col.colStatusCode === 8) ? "grid-row-body selected" : "grid-row-body reserved"
                                                }>
                                            {col.colCode}
                                        </td> 
                                    )
                                }
                            </tr> 
                        )
                    }
                </tbody>
            </table>
            <table className="grid table table-header-rotated vault-table">
                <thead>
                    <tr>
                        <th></th>
                        {
                            header2.map(col =>
                                col.column.map((col, idx) =>
                                    <th className="grid-column-header" key={idx} ><div><span>{col}</span></div></th>
                                )
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    { 
                        vaultData && vaultData.map((row, idxRow) => 
                        
                            <tr key={idxRow}>
                                <th className="grid-row-header">{row.pillarLevel}</th>
                                {   
                                    row.pillarCols.slice(8, 16) && row.pillarCols.slice(4, 8).map((col, idxCol) => 
                                        <td 
                                            key={idxCol} 
                                            onDoubleClick = {e => onClickVault(idxRow, idxCol + 4, col)}
                                            className={
                                                    (col.colStatusCode === 1) ? "grid-row-body available" : 
                                                    (col.colStatusCode === 8) ? "grid-row-body selected" : "grid-row-body reserved"
                                                }>
                                            {col.colCode}
                                        </td> 
                                    )
                                }
                            </tr> 
                        )
                    }
                </tbody>
                
            </table>
        </Fragment>
    );

}

export default PillarSelectionF2;
import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

const onClickAccount = (e) => {
    document.querySelector('.menu-container').classList.toggle('active');
}


const onLogout = (e) => {
    localStorage.removeItem("fsb");
}

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
          <Component
            {...props}
            router={{ location, navigate, params }}
          />
        );
      }
    
      return ComponentWithRouterProp;
}


const DropdownMenu = () => {

    var account = JSON.parse(localStorage.getItem("fsb")) || [];

    return (
        <div className="user-menu-wrap">
            <a className="mini-photo-wrapper" href="#" onClick={e => onClickAccount(e)}>
                <i className="fa fa-user"></i>&nbsp;&nbsp; My Account
            </a>
            
            <div className="menu-container">
                <ul className="user-menu">
                    <a className="close-menu" href="#" onClick={e => onClickAccount(e)}>
                        <i className="fa fa-close"></i>
                    </a>
                    <div className="profile-highlight">
                        <div className="details">
                            <div id="profile-name">{account.firstName} {account.lastName}</div>
                            <div id="profile-footer">{account.emailAddress}</div>
                        </div>
                    </div>
                    <li className="user-menu__item">
                        <Link className="user-menu-link" to="/pages/secured/user/my-profile">
                            <i className="fa fa-user"></i>
                            <div>My Profile</div>
                        </Link>
                    </li>
                    <li className="user-menu__item">
                        <Link className="user-menu-link" to="/pages/secured/user/my-purchases">
                            <i className="fa fa-shopping-cart"></i>
                            <div>My Purchases</div>
                        </Link>
                    </li>
                    <li className="user-menu__item">
                        <Link className="user-menu-link" onClick={e => onLogout(e)} to="/pages/login">
                            <i className="fa fa-sign-out"></i>
                            <div>Signout</div>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default withRouter(DropdownMenu);
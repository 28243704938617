import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Layout from '../components/Layouts/Layout'
import Spinner from "../components/Spinner";

import { signin, isAuthenticated } from '../apiCall/auth';
import { loggedIn } from '../SharedCode';


const Login = () => {

    const [values, setValues] = useState({
        email: '',
        password: '',
        role: '',
        success: false, 
        error: false
    });

    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        goTop();
        redirectUser(false);
        clearCacheData();
        if(isAuthenticated()) {
            setIsLogged(true)
        }
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const [errors, setErrors] = useState({
        email: '',
        password: '',
        messages: ''
    });

    const [showSpinner, setShowSpinner] = useState(false);

    const navigate = useNavigate();

    const clickSubmit = event => {
        event.preventDefault();

        setShowSpinner(true);

        const { email, password } = values;

        var request = {
            EmailAddress: email, 
            UserPassword: password
        };

        setErrors({...errors, email: '', password: '', message: '' });
        setValues({...values, error: true });

        signin(request).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);

                localStorage.setItem("fsb", JSON.stringify(res.dataDictionary));
                setValues({...values, error: false, success: true, role: res.dataDictionary.role });
                redirectUser(true);
            } 
            else {
                setShowSpinner(false);

                if (res.status === 400) {
                    if(res.errors.EmailAddress) {
                        setErrors({...errors, email: res.errors.EmailAddress[0] });
                        setValues({...values, error: true, success: false });
                    }   

                    if(res.errors.UserPassword) {
                        setErrors({...errors, contactNumer: res.errors.UserPassword[0] });
                        setValues({...values, error: true, success: false });
                    }   
                }
                else {
                    setErrors({ ...errors, messages: res.message });
                }
            }
        })
    }
    
    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });

        if(name === 'email') {
            setErrors({...errors, email: '', message: '' });
        } 

        if(name === 'password') {
            setErrors({...errors, password: '', message: '' });
        }
    };

    const redirectUser = (isAutoRedirect) => {
        
        if(isAutoRedirect) 
        {
            if (values && values.role === 'admin') {
                navigate('/pages/secured/admin/dashboard');
            } else {
                navigate('/pages/secured/user/reservation');
            }
        } else {
            if (values.success) {
                if (values && values.role === 'admin') {
                    navigate('/pages/secured/admin/dashboard');
                } else {
                    navigate('/pages/secured/user/reservation');
                }
            }
        }
    };

    const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
    };

    return (
        <Layout>
            <main>
                <div className="container">
                    <div className="row mt-160">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">Customer Login</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <form>
                                <div id="loginform">
                                    { showSpinner && <Spinner /> }
                                    <div className="row">
                                        <h3 id="warning-message">{ errors.messages }</h3>
                                    </div>
                                    <div className="row mb-40">
                                        <label>Email</label>
                                        <input type="email" className="form-control" placeholder="Enter your email" onChange={handleChange('email')} value={ values.email } />
                                        <div className="err-msg" style={{ display: errors.email ? '' : 'none' }}>
                                            <span>{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-40">
                                        <label>Password</label>
                                        <input type="password" className="form-control" placeholder="Enter your password" onChange={handleChange('password')} value={ values.password } />
                                        <div className="err-msg">
                                            <span>{errors.password}</span>
                                        </div>
                                    </div>
                                    <div id="button" className="row mb-2">
                                        <button onClick={clickSubmit}>
                                            Login
                                        </button>
                                    </div>
                                    <div className="row mb-10">
                                        <label className="right" style={{width:"100%"}}>
                                            <Link to="/pages/forgot-password" style={{marginRight:"50px"}}>Forgot Password</Link>
                                        </label>
                                    </div>
                                    <div id="signup-link">
                                        <label>Don't have an account? Register <Link to="/pages/signup">here</Link>.</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </main>
            { 
                isLogged && loggedIn() 
            }
        </Layout>
    );
};

export default Login;
import { API } from '../config'

export const signin = (request) => {
    
    return fetch(`${API}/auth/customer/signin`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {

        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const signup = (request) => {
    
    return fetch(`${API}/auth/customer/signup`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {

        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const forgotPassword = (request) => {
    
    return fetch(`${API}/auth/customer/forgot-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {

        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    if (localStorage.getItem('fsb')) {
        return JSON.parse(localStorage.getItem('fsb'));
    } else {
        return false;
    }
};
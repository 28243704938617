import React, { Fragment } from 'react';

const OrderPaymentOptionFullPayment = (props) => {
    return(
        (!props.paymentOption.isInstallment && props.paymentOption.paymentOptionId == 1 && props.product.paymentOptionId == 1) &&
            <Fragment>
                <div className="description">
                    <table className="font-size-12 borderless">
                        <tbody>
                            <tr>
                                <td><b>Full Payment</b></td>
                                <td><b>{props.formatCurrency(props.product.checkoutAmount)}</b></td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
            </Fragment>
    )
}

export default OrderPaymentOptionFullPayment;
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getVaultOssuary, addVaultCart } from '../../../apiCall/vault';

import CustomerLayout from '../../../components/Layouts/CustomerLayout';

import PillarSelectionE1 from '../../../components/Ossuary1/PillarSelectionE1';
import PillarSelectionE2 from '../../../components/Ossuary1/PillarSelectionE2';
import PillarSelectionE3 from '../../../components/Ossuary1/PillarSelectionE3';
import PillarSelectionE4 from '../../../components/Ossuary1/PillarSelectionE4';

import PillarSelectionA1 from '../../../components/Ossuary1/PillarSelectionA1';
import PillarSelectionA2 from '../../../components/Ossuary1/PillarSelectionA2';

import PillarSelectionB1 from '../../../components/Ossuary1/PillarSelectionB1';
import PillarSelectionB2 from '../../../components/Ossuary1/PillarSelectionB2';
import PillarSelectionC1 from '../../../components/Ossuary1/PillarSelectionC1';
import PillarSelectionC2 from '../../../components/Ossuary1/PillarSelectionC2';
import PillarSelectionD1 from '../../../components/Ossuary1/PillarSelectionD1';
import PillarSelectionD2 from '../../../components/Ossuary1/PillarSelectionD2';
import PillarSelectionF1 from '../../../components/Ossuary1/PillarSelectionF1';
import PillarSelectionF2 from '../../../components/Ossuary1/PillarSelectionF2';
import PillarSelectionG1 from '../../../components/Ossuary1/PillarSelectionG1';
import PillarSelectionG2 from '../../../components/Ossuary1/PillarSelectionG2';
import PillarSelectionH1 from '../../../components/Ossuary1/PillarSelectionH1';
import PillarSelectionH2 from '../../../components/Ossuary1/PillarSelectionH2';

import Spinner from '../../../components/Spinner';


const PillarOssuary = props => {

    const [displayLabel, setDisplayLabel] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setErrors] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [back, setBack] = useState(false);
    const [summaryId, setSummaryId] = useState(null);
    const [building, setBuilding] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);

    // P01
    const [selectedVault, setSelectedVault] = useState([]);
    const [vaultE1Data, setVaultE1Data] = useState([]);
    const [vaultE2Data, setVaultE2Data] = useState([]);
    const [vaultE3Data, setVaultE3Data] = useState([]);
    const [vaultE4Data, setVaultE4Data] = useState([]);

    // P02
    const [vaultB1Data, setVaultB1Data] = useState([]);
    const [vaultB2Data, setVaultB2Data] = useState([]);

    // P03 to 11 & 18 to 24
    const [vaultC1Data, setVaultC1Data] = useState([]);
    const [vaultC2Data, setVaultC2Data] = useState([]);
    const [vaultD1Data, setVaultD1Data] = useState([]);
    const [vaultD2Data, setVaultD2Data] = useState([]);

    // P12 to 17
    const [vaultA1Data, setVaultA1Data] = useState([]);
    const [vaultA2Data, setVaultA2Data] = useState([]);

    // Suite 01
    const [vaultG1Data, setVaultG1Data] = useState([]);
    const [vaultG2Data, setVaultG2Data] = useState([]);
    const [vaultF1Data, setVaultF1Data] = useState([]);
    const [vaultF2Data, setVaultF2Data] = useState([]);

    // Suite 02
    const [vaultH1Data, setVaultH1Data] = useState([]);
    const [vaultH2Data, setVaultH2Data] = useState([]);
        
    const [vaultTemplate, setVaultTemplate] = useState('');

    const goTop = () => {
        window.scrollTo(0, 0);
    }

    const params = useParams();

    useEffect(() => {

        const buildingCode = decodeURI(params.buildingCode);
        const floorCode = decodeURI(params.floorCode);
        const pillar = decodeURI(params.pillar);
        const label = decodeURI(params.encLabel);

        setDisplayLabel(decodeURI(label))
        setBuilding(buildingCode);

        if(pillar === 'P01') { 
            getVaultList(buildingCode, floorCode, 'E1', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'E2', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'E3', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'E4', pillar, 'E');
            setVaultTemplate('P01');
        } else if (pillar === 'P02') {
            getVaultList(buildingCode, floorCode, 'B1', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'B2', pillar, 'W');
            setVaultTemplate('P02');
        } else if (pillar === 'P03' || pillar === 'P04' || pillar === 'P05' || pillar === 'P06' || pillar === 'P07' || pillar === 'P08' || pillar === 'P09' || pillar === 'P10') {
            getVaultList(buildingCode, floorCode, 'C1', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'C2', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'D1', pillar, 'W');
            getVaultList(buildingCode, floorCode, 'D2', pillar, 'W');
            setVaultTemplate('P03_10');
        } else if (pillar === 'P11') {
            getVaultList(buildingCode, floorCode, 'D1', pillar, 'W');
            getVaultList(buildingCode, floorCode, 'D2', pillar, 'W');
            setVaultTemplate('P11');
        } else if (pillar === 'P12') {
            getVaultList(buildingCode, floorCode, 'A2', pillar, 'N');
            setVaultTemplate('P12');
        } else if (pillar === 'P13' || pillar === 'P14' || pillar === 'P15' || pillar === 'P16' || pillar === 'P17') {
            getVaultList(buildingCode, floorCode, 'A1', pillar, 'S');
            getVaultList(buildingCode, floorCode, 'A2', pillar, 'N');
            setVaultTemplate('P13_17');
        } else if (pillar === 'P18') {
            getVaultList(buildingCode, floorCode, 'C1', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'C2', pillar, 'E');
            setVaultTemplate('P18');
        } else if (pillar === 'P19' || pillar === 'P20' || pillar === 'P21' || pillar === 'P22' || pillar === 'P23') {
            getVaultList(buildingCode, floorCode, 'C1', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'C2', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'D1', pillar, 'W');
            getVaultList(buildingCode, floorCode, 'D2', pillar, 'W');
            setVaultTemplate('P19_23');
        } else if (pillar === 'P24') {
            getVaultList(buildingCode, floorCode, 'D1', pillar, 'W');
            getVaultList(buildingCode, floorCode, 'D2', pillar, 'W');
            setVaultTemplate('P24');
        } else if (pillar === 'S01') {
            getVaultList(buildingCode, floorCode, 'F1', pillar, 'S');
            getVaultList(buildingCode, floorCode, 'F2', pillar, 'N');
            getVaultList(buildingCode, floorCode, 'G1', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'G2', pillar, 'W');
            setVaultTemplate('S01');
        } else if (pillar === 'S02') {
            getVaultList(buildingCode, floorCode, 'H1', pillar, 'E');
            getVaultList(buildingCode, floorCode, 'H2', pillar, 'W');
            setVaultTemplate('S02');
        }
        
        goTop();

    }, [props]);

    
    const getVaultList = (buildingCode, floorCode, rowNumber, pillar, pillarDirection) => {
        setShowSpinner(true);
        getVaultOssuary(buildingCode, floorCode, rowNumber, pillar, pillarDirection).then(res => {
            if (res.isSuccess === true) {
                
                if(pillar === 'P01' && rowNumber === 'E1') { setVaultE1Data(res.dataDictionary.data); }
                if(pillar === 'P01' && rowNumber === 'E2') { setVaultE2Data(res.dataDictionary.data); }
                if(pillar === 'P01' && rowNumber === 'E3') { setVaultE3Data(res.dataDictionary.data); }
                if(pillar === 'P01' && rowNumber === 'E4') { setVaultE4Data(res.dataDictionary.data); }
                
                if(pillar === 'P02' && rowNumber === 'B1') { setVaultB1Data(res.dataDictionary.data); }
                if(pillar === 'P02' && rowNumber === 'B2') { setVaultB2Data(res.dataDictionary.data); }

                if(rowNumber === 'C1') { setVaultC1Data(res.dataDictionary.data); }
                if(rowNumber === 'C2') { setVaultC2Data(res.dataDictionary.data); }
                if(rowNumber === 'D1') { setVaultD1Data(res.dataDictionary.data); }
                if(rowNumber === 'D2') { setVaultD2Data(res.dataDictionary.data); }

                if(rowNumber === 'A1') { setVaultA1Data(res.dataDictionary.data); }
                if(rowNumber === 'A2') { setVaultA2Data(res.dataDictionary.data); }

                if(rowNumber === 'F1') { setVaultF1Data(res.dataDictionary.data); }
                if(rowNumber === 'F2') { setVaultF2Data(res.dataDictionary.data); }

                if(rowNumber === 'G1') { setVaultG1Data(res.dataDictionary.data); }
                if(rowNumber === 'G2') { setVaultG2Data(res.dataDictionary.data); }

                if(rowNumber === 'H1') { setVaultH1Data(res.dataDictionary.data); }
                if(rowNumber === 'H2') { setVaultH2Data(res.dataDictionary.data); }

                setErrors("");
                setShowSpinner(false);
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };

    const addVaultToCart = (selectedVault) => {
        if(selectedVault.length > 0) {
            setShowSpinner(true);
            addVaultCart(selectedVault).then(res => {
                if (res.isSuccess === true) {
                    setSummaryId(res.dataDictionary.id);
                    setShowSpinner(false);
                    setErrors("");
                    setRedirect(true);
                } 
                else {
                    setShowSpinner(false);
                    setErrors(res.message);
                    setRedirect(false);
                }
            })
        } else {
            setShowSpinner(false);
            setRedirect(false);
            setShowConfirmation(true);
        }
    }

    const fillPillar01 = () => {
        return (
            <>
                <h2 className="mb-12">{displayLabel} - East</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg35-width">
                        <PillarSelectionE1 vault={vaultE1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionE2 vault={vaultE2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionE3 vault={vaultE3Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionE4 vault={vaultE4Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    const fillPillar02 = () => {
        return (
            <>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <h2 className="mb-12">{displayLabel} - East</h2>
                        <PillarSelectionB1 vault={vaultB1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <h2 className="mb-16 mt-20">{displayLabel} - West</h2>
                        <PillarSelectionB2 vault={vaultB2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>

            </>
        );
    };

    const fillPillar03_10 = () => {
        return (
            <>
                <h2 className="mb-12">{displayLabel} - East</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg3-width">
                        <PillarSelectionC1 vault={vaultC1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionC2 vault={vaultC2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                <h2 className="mb-16 mt-40">{displayLabel} - West</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg3-width">
                        <PillarSelectionD1 vault={vaultD1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionD2 vault={vaultD2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    const fillPillar11 = () => {
        return (
            <>
                <div className="mb-12 scroll-div">
                    <div className="lg3-width">
                        <h2 className="mb-16 mt-20">{displayLabel} - West</h2>
                        <PillarSelectionD1 vault={vaultD1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionD2 vault={vaultD2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    const fillPillar12 = () => {
        return (
            <>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <h2 className="mb-16 mt-20">{displayLabel} - North</h2>
                        <PillarSelectionA2 vault={vaultA2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    
    const fillPillar18 = () => {
        return (
            <>
                <div className="mb-12 scroll-div">
                    <div className="lg3-width">
                        <h2 className="mb-12">{displayLabel} - East</h2>
                        <PillarSelectionC1 vault={vaultC1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionC2 vault={vaultC2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    const fillPillar13_17 = () => {
        return (
            <>
                <h2 className="mb-16 mt-40">{displayLabel} - North</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionA2 vault={vaultA2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                <h2 className="mb-12">{displayLabel} - South</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionA1 vault={vaultA1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                
            </>
        );
    };

    const fillPillar19_23 = () => {
        return (
            <>
                <h2 className="mb-12">{displayLabel} - East</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg3-width">
                        <PillarSelectionC1 vault={vaultC1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionC2 vault={vaultC2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                <h2 className="mb-16 mt-40">{displayLabel} - West</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg3-width">
                        <PillarSelectionD1 vault={vaultD1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionD2 vault={vaultD2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    const fillPillar24 = () => {
        return (
            <>
                <h2 className="mb-16 mt-40">{displayLabel} - West</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg3-width">
                        <PillarSelectionD1 vault={vaultD1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                        <PillarSelectionD2 vault={vaultD2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    const fillSuite01 = () => {
        return (
            <>
                <h2 className="mb-16 mt-40">{displayLabel} - North</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionF2 vault={vaultF2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                <h2 className="mb-16 mt-40">{displayLabel} - South</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionF1 vault={vaultF1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                
                <h2 className="mb-16 mt-40">{displayLabel} - East</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionG1 vault={vaultG1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                <h2 className="mb-16 mt-40">{displayLabel} - West</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionG2 vault={vaultG2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
            </>
        );
    };

    const fillSuite02 = () => {
        return (
            <>
                <h2 className="mb-16 mt-40">{displayLabel} - East</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionH1 vault={vaultH1Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                <h2 className="mb-16 mt-40">{displayLabel} - West</h2>
                <div className="mb-12 scroll-div">
                    <div className="lg-width">
                        <PillarSelectionH2 vault={vaultH2Data} setSelectedVault={setSelectedVault} selectedVault={selectedVault} />
                    </div>
                </div>
                
            </>
        );
    };

    const onClickRedirect = () => {
        addVaultToCart(selectedVault)
    }

    const onClickShowConfirmation = () => {
        setShowConfirmation(true);
    }

    const onClickBack = () => {
        setBack(true);
    }
    

    const redirectToCart = () => {
        var url = `/pages/secured/user/cart/${summaryId}`;
        if (redirect) {
            return <Navigate to={url} />;
        }
    };

    const redirectToBack = () => {
        var url = `/pages/secured/user/floor/${building}`;
        if (back) {
            return <Navigate to={url} />;
        }
    };



    return (
        <CustomerLayout>
            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="font-size-16">Ossuary - Vault Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body  className="font-size-16">
                    Please select vaults or click My Account to choose a vault you previously 
                    selected to proceed with purchase.
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn bg-color-primary" variant="secondary" onClick={handleCloseConfirmation}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <main>
                { showSpinner && <Spinner /> }
                <section className="reservation-section">
                    <div className="row mb-12">
                        <div className="col-md">
                            <h3 className="err-msg">{ error }</h3>
                        </div>
                    </div>
                    { (vaultTemplate === 'P01') && fillPillar01() }
                    { (vaultTemplate === 'P02') && fillPillar02() }
                    { (vaultTemplate === 'P03_10') && fillPillar03_10() }
                    { (vaultTemplate === 'P11') && fillPillar11() }
                    { (vaultTemplate === 'P12') && fillPillar12() }
                    { (vaultTemplate === 'P13_17') && fillPillar13_17() }
                    { (vaultTemplate === 'P18') && fillPillar18() }
                    { (vaultTemplate === 'P19_23') && fillPillar19_23() }
                    { (vaultTemplate === 'P24') && fillPillar24() }
                    { (vaultTemplate === 'S01') && fillSuite01() }
                    { (vaultTemplate === 'S02') && fillSuite02() }
                    <div className="row mb-12">
                        <div className="col-md">
                            <button onClick={onClickBack} className="btn btn--cancel mr-4">Back</button>
                            <button onClick={onClickRedirect} className="next btn btn--primary">Add to Purchase</button>
                        </div>
                        <div className="col-md">
                            <h2 className="notice">
                                Please select vaults or click My Account to choose a vault you previously 
                                selected to proceed with purchase.
                            </h2>
                        </div>
                    </div>
                </section>
            </main>
            { redirectToCart() }
            { redirectToBack() }
        </CustomerLayout>
    );
};

export default PillarOssuary;
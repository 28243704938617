import React, { Fragment } from 'react';

const FloorSelection = (props) => {

    const imgBuilding1 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1650160953/project01/layout/FloorPlanOssuary_2_xgj4ta.png'

    const handlePillarClick = (pillar, label) => {
        props.validation(pillar, label)
        
    }

    const building1 = () => (
        <div className="dragscroll image-map">
            <img id="Image-Maps-Com-image-maps-2022-04-17-065419" 
                src="https://res.cloudinary.com/fsb-land-holding/image/upload/v1650160953/project01/layout/FloorPlanOssuary_2_xgj4ta.png" 
                border="0" width="1600" height="1280" 
                useMap="#image-maps-2022-04-17-065419" 
                alt="" />
            <map name="image-maps-2022-04-17-065419" id="ImageMapsCom-image-maps-2022-04-17-065419">
                <area id="P02" alt="Pillar 02" title="Pillar 02" shape="rect" coords="370,521,389,620" 
                    onDoubleClick={event => handlePillarClick("P02", "Pillar 02")} className="cursor-hand"/>
                    
                <area id="P03" alt="Pillar 03" title="Pillar 03" shape="rect" coords="407,486,424,656"
                    onDoubleClick={event => handlePillarClick("P03", "Pillar 03")} className="cursor-hand"/>

                <area id="P04" alt="Pillar 04" title="Pillar 04" shape="rect" coords="443,485,458,655" 
                    onDoubleClick={event => handlePillarClick("P04", "Pillar 04")} className="cursor-hand"/>

                <area id="P05" alt="Pillar 05" title="Pillar 05" shape="rect" coords="476,483,498,653" 
                    onDoubleClick={event => handlePillarClick("P05", "Pillar 05")} className="cursor-hand"/>

                <area id="P06" alt="Pillar 06" title="Pillar 06" shape="rect" coords="512,488,533,654" 
                    onDoubleClick={event => handlePillarClick("P06", "Pillar 06")} className="cursor-hand"/>

                <area id="P07" alt="Pillar 07" title="Pillar 07" shape="rect" coords="548,485,567,651" 
                    onDoubleClick={event => handlePillarClick("P07", "Pillar 07")} className="cursor-hand"/>

                <area id="P08" alt="Pillar 08" title="Pillar 08" shape="rect" coords="584,488,600,654" 
                    onDoubleClick={event => handlePillarClick("P08", "Pillar 08")} className="cursor-hand"/>

                <area id="P09" alt="Pillar 09" title="Pillar 09" shape="rect" coords="620,487,640,653" 
                    onDoubleClick={event => handlePillarClick("P09", "Pillar 09")} className="cursor-hand"/>

                <area id="P10" alt="Pillar 10" title="Pillar 10" shape="rect" coords="655,492,673,658" 
                    onDoubleClick={event => handlePillarClick("P10", "Pillar 10")} className="cursor-hand"/>

                <area id="P11" alt="Pillar 11" title="Pillar 11" shape="rect" coords="688,493,706,659" 
                    onDoubleClick={event => handlePillarClick("P11", "Pillar 11")} className="cursor-hand"/>

                <area id="P17" alt="Pillar 17" title="Pillar 17" shape="rect" coords="616,278,702,297" 
                    onDoubleClick={event => handlePillarClick("P17", "Pillar 17")} className="cursor-hand"/>

                <area id="P16" alt="Pillar 16" title="Pillar 16" shape="rect" coords="619,313,705,332" 
                    onDoubleClick={event => handlePillarClick("P16", "Pillar 16")} className="cursor-hand"/>

                <area id="P15" alt="Pillar 15" title="Pillar 15" shape="rect" coords="618,350,704,369" 
                    onDoubleClick={event => handlePillarClick("P15", "Pillar 15")} className="cursor-hand"/>

                <area id="P14" alt="Pillar 14" title="Pillar 14" shape="rect" coords="617,385,703,404" 
                    onDoubleClick={event => handlePillarClick("P14", "Pillar 14")} className="cursor-hand"/>

                <area id="P13" alt="Pillar 13" title="Pillar 13" shape="rect" coords="619,421,705,440" 
                    onDoubleClick={event => handlePillarClick("P13", "Pillar 13")} className="cursor-hand"/>

                <area id="P12" alt="Pillar 12" title="Pillar 12" shape="rect" coords="620,452,706,471" 
                    onDoubleClick={event => handlePillarClick("P12", "Pillar 12")} className="cursor-hand"/>

                <area id="P18" alt="Pillar 18" title="Pillar 18" shape="rect" coords="780,276,796,444" 
                    onDoubleClick={event => handlePillarClick("P18", "Pillar 18")} className="cursor-hand"/>

                <area id="P19" alt="Pillar 19" title="Pillar 19" shape="rect" coords="812,279,828,447" 
                    onDoubleClick={event => handlePillarClick("P19", "Pillar 19")} className="cursor-hand"/>

                <area id="P20" alt="Pillar 20" title="Pillar 20" shape="rect" coords="848,281,864,449" 
                    onDoubleClick={event => handlePillarClick("P20", "Pillar 20")} className="cursor-hand"/>

                <area id="P21" alt="Pillar 21" title="Pillar 21" shape="rect" coords="884,282,900,450" 
                    onDoubleClick={event => handlePillarClick("P21", "Pillar 21")} className="cursor-hand"/>

                <area id="P22" alt="Pillar 22" title="Pillar 22" shape="rect" coords="919,283,935,451" 
                    onDoubleClick={event => handlePillarClick("P22", "Pillar 22")} className="cursor-hand"/>

                <area id="P23" alt="Pillar 23" title="Pillar 23" shape="rect" coords="955,281,971,449" 
                    onDoubleClick={event => handlePillarClick("P23", "Pillar 23")} className="cursor-hand"/>
                
                <area id="P24" alt="Pillar 24" title="Pillar 24" shape="rect" coords="989,283,1005,451"
                    onDoubleClick={event => handlePillarClick("P24", "Pillar 24")} className="cursor-hand"/>
                
                <area id="S01" alt="Suite 01" title="Suite 01" shape="rect" coords="486,391,555,465" 
                    onDoubleClick={event => handlePillarClick("S01", "Suite 01")} className="cursor-hand"/>
                
                <area id="S02" alt="Suite 02" title="Suite 02" shape="rect" coords="559,390,596,464" 
                    onDoubleClick={event => handlePillarClick("S02", "Suite 02")} className="cursor-hand"/>
                
                <area shape="rect" coords="1598,1278,1600,1280" alt="Image Map" title="Image Map" />
                <area id="P01" alt="Pillar 01" title="Pillar 01" shape="poly" coords="356,490,367,497,331,537,373,647,355,650,317,535" 
                    onDoubleClick={event => handlePillarClick("P01", "Pillar 01")} className="cursor-hand"/>
            </map>
        </div>

    )

    return (
        <Fragment>
            
            { (props.template !== 'T1') && building1() }
                
        </Fragment>
    )
}

export default FloorSelection;
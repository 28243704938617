import React from 'react';
import Layout from '../components/Layouts/Layout'
import UnderConstruction from '../components/UnderConstruction'

const faq = () => {
    return (
        <Layout>
            <UnderConstruction title="Frequently Asked Questions" />
        </Layout>
    );
};


export default faq;
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Columbarium from './pages/columbarium'
import Products from './pages/products'
import Services from './pages/services'
import AfterSales from './pages/afterSales'
import Login from './pages/login'
import ContactUs from './pages/contact'
import FAQ from './pages/faq'

import ForgotPassword from './pages/forgot-password'
import ForgotPasswordConfirmation from './pages/forgot-password-confirmation'
import SignUp from './pages/signup'
import SignUpConfirmation from './pages/signup_confirm'

import Building from './pages/secured/reservation/building'
import FloorImageMap from './pages/secured/reservation/floor-image-map';
import PillarOssuary from './pages/secured/reservation/pillar-ossuary';
import Cart from './pages/secured/reservation/cart';
import OrderReview from './pages/secured/reservation/order-review'
import CustomerForm from './pages/secured/reservation/customerForm';
import OrderConfirmation from './pages/secured/reservation/orderConfirmation';
import OrderPayment from './pages/secured/reservation/orderPayment';
import PaymentResult from './pages/secured/reservation/payment-result';
import ReservationResult from './pages/secured/reservation/reservationResult';

import MyProfile from './pages/secured/profiles/myProfile';
import ProfileUpdateConfirmation from './pages/secured/profiles/profile-update-confim';

import MyPurchase from './pages/secured/profiles/myPurchases';
import PurchaseDetails from './pages/secured/profiles/purchase-details';

import AdminDashboard from './pages/secured/admin/dashboard';
import BuildingAdmin from './pages/secured/admin/building-admin';
import PurchaseList from './pages/secured/admin/purchase-list'
import VaultList from './pages/secured/admin/vault-list'
import AdminPurchaseDetails from './pages/secured/admin/admin-purchase-details';
import TermsOfUse from './pages/terms-of-use';

import CartContinue from './pages/secured/reservation/cart-continue';
import OrderReviewContinue from './pages/secured/reservation/order-review-continue';
import OrderPaymentCancel from './pages/secured/reservation/order-payment-cancel';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Columbarium />} />
                <Route path="/pages/columbarium" exact element={<Columbarium />} />
                <Route path="/pages/products" exact element={<Products />} />
                <Route path="/pages/services" exact element={<Services />} />
                <Route path="/pages/afterSales" exact element={<AfterSales />} />
                <Route path="/pages/login" exact element={<Login />} />
                <Route path="/pages/contact" exact element={<ContactUs />} />
                <Route path="/pages/terms-of-use" exact element={<TermsOfUse />} />
                <Route path="/pages/faq" exact element={<FAQ />} />
                <Route path="/pages/forgot-password" exact element={<ForgotPassword />} />
                <Route path="/pages/forgot-password-confirmation" exact element={<ForgotPasswordConfirmation />} />
                <Route path="/pages/signup" exact element={<SignUp />} />
                <Route path="/pages/secured/signup_confirm" exact element={<SignUpConfirmation />} />

                <Route path="/pages/secured/user/reservation" exact element={<Building />} />
                <Route path="/pages/secured/user/floor/:buildingCode" exact element={<FloorImageMap />} />
                <Route path="/pages/secured/user/pillar/:buildingCode/:floorCode/:pillar/:encLabel" exact element={<PillarOssuary />} />
                
                
                { 
                    // Update this 
                }
                <Route path="/pages/secured/user/cart/:summaryId" exact element={<Cart />} />
                <Route path="/pages/secured/user/order-review/:summaryId" exact element={<OrderReview />} />
                
                <Route path="/pages/secured/user/form/:summaryId" exact element={<CustomerForm />} />
                <Route path="/pages/secured/user/confirmation" exact element={<OrderConfirmation />} />
                <Route path="/pages/secured/user/order-payment" exact element={<OrderPayment />} />
                <Route path="/pages/secured/user/payment-status/:summaryId" exact element={<PaymentResult />} />
                <Route path="/pages/secured/user/reservation-result/:summaryId" exact element={<ReservationResult />} />

                <Route path="/pages/secured/user/my-profile" exact element={<MyProfile />} />
                <Route path="/pages/secured/user/profile/acknowledgement" exact element={<ProfileUpdateConfirmation />} />

                <Route path="/pages/secured/user/my-purchases" exact element={<MyPurchase />} />
                <Route path="/pages/secured/user/my-purchases/details/:summaryId" exact element={<PurchaseDetails />} />

                <Route path="/pages/secured/admin/dashboard" exact element={<AdminDashboard />} />
                <Route path="/pages/secured/user/admin-reservation" exact element={<BuildingAdmin />} />
                <Route path="/pages/secured/user/admin-purchase-list" exact element={<PurchaseList />} />
                <Route path="/pages/secured/user/admin-vault-list" exact element={<VaultList />} />
                <Route path="/pages/secured/user/admin-purchase-details/:source/:summaryId" exact element={<AdminPurchaseDetails />} />

                <Route path="/pages/secured/user/cart-continue/:summaryId" exact element={<CartContinue />} />
                <Route path="/pages/secured/user/order-review-continue/:summaryId" exact element={<OrderReviewContinue />} />
                <Route path="/pages/secured/user/order-payment-cancel/:summaryId" exact element={<OrderPaymentCancel />} />

                
                
            </Routes>
        </BrowserRouter>
    );
};



export default Router;
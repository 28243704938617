import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
          <Component
            {...props}
            router={{ location, navigate, params }}
          />
        );
      }
    
      return ComponentWithRouterProp;
}

const BreadcrumbsMenu = ({ history }) => {

    let location = useLocation();

    const BreadcrumbsStyle = () => {
        return {
            fontSize: '12pt'
        };
    }

    const menu = [
        { name: "Building", path: "/pages/secured/user/reservation"},
        { name: "Floor and Pillar", path: "/pages/secured/user/floor/TO1"},
        { name: "Vault", path: localStorage.getItem("prev_vault_url")},
        { name: "Cart", path: localStorage.getItem("prev_cart_url")}
    ]

    if (location.pathname.includes("/floor/")){
        menu.length = 2;
    }
    if (location.pathname.includes("/pillar/")) {
        localStorage.setItem("prev_vault_url", location.pathname)
        menu.length = 3;
    }
    if (location.pathname.includes("/cart/")) {
        localStorage.setItem("prev_cart_url", location.pathname)
    }

    var label  = '';
    var label2 = '';
    const pathname = location.pathname;
    if(pathname === '/pages/secured/my-purchase') {
        label  = 'My Purchases';
    } else if (pathname.includes('/pages/secured/user/my-purchases')) {
        label  = 'My Purchases';
        label2 = 'Double click to select a record'
    } else if (pathname.includes('/pages/secured/my-purchase/details/')) {
        label  = 'Purchase Detail';
    } else if (pathname === '/pages/secured/user/reservation') {
        label  = 'Step 1: Building Selection';
    } else if (pathname.includes('/pages/secured/user/floor/')) {
        label  = 'Step 2: Pillar Selection';
        label2 = 'Double click on the Pillar to select'
    } else if (pathname.includes('/pages/secured/user/pillar/')) {
        label  = 'Step 3: Vault Selection';
        label2 = 'Double click on the vault to select or unselect.'
    
    } else if (pathname.includes('/pages/secured/user/cart')) {
        label  = 'Step 4: Payment Option';
    
    } else if (pathname.includes('/pages/secured/user/cart-continue')) {
        label  = 'Step 4: Payment Option - Continuation';

    } else if (pathname.includes('/pages/secured/user/order-review')) {
        label  = 'Step 5: Order Review';
    } else if (pathname.includes('/pages/secured/user/form')) {
        label  = 'Step 6: Customer Purchase Online Form';
    } else if (pathname.includes('/pages/secured/user/confirmation')) {
        label  = '';
    } else if (pathname.includes('/pages/secured/admin/dashboard')) {
        label  = 'Admin Portal';
    } else if (pathname.includes('/pages/secured/user/admin-reservation')) {
        label  = 'Step 1: Building Selection';
    }

    return (
        <Breadcrumb style={BreadcrumbsStyle()} className="reservation-breadcrumbs">
            <span>
                <h2>{label}</h2>
                <h4>{label2}</h4>
            </span>
        </Breadcrumb>
    );
};

export default withRouter(BreadcrumbsMenu);

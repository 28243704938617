import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import PaginatorFactory from 'react-bootstrap-table2-paginator'
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import Spinner from '../../../components/Spinner';
import { searchVaultList } from '../../../apiCall/vault';

const VaultList = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [data, setData] = useState([]);
    const [viewDetail, setViewDetail] = useState(false);
    const [values, setValues] = useState({
        vaultCode: '',
        floorPlanCode: '',
        vaultStatusId: '',
        pillarCode: '',
        buildingCode: '',
        orderReferenceNumber: ''
    })
    
    const [errors, setErrors] = useState({
        messages: ''
    });

    const statuses = [
        { value: '', label: 'Select All' },
        { value: '1', label: 'Open' },
        { value: '2', label: 'Purchased' },
        { value: '3', label: 'Submitted' },
        { value: '4', label: 'Pending' },
        { value: '5', label: 'Reserved' },
        { value: '6', label: 'Held by Broker' },
        { value: '7', label: 'Held by In-house' }
    ]

    const buildings = [
        { value: '', label: 'Select All' },
        { value: 'TO1', label: 'The Ossuary 1' },
        { value: 'TO2', label: 'The Ossuary 2' }
    ]

    const pillars = [
        { value: '', label: 'Select All' },
        { value: 'P01', label: 'Pillar 01' },
        { value: 'P02', label: 'Pillar 02' },
        { value: 'P03', label: 'Pillar 03' },
        { value: 'P04', label: 'Pillar 04' },
        { value: 'P05', label: 'Pillar 05' },
        { value: 'P06', label: 'Pillar 06' },
        { value: 'P07', label: 'Pillar 07' },
        { value: 'P08', label: 'Pillar 08' },
        { value: 'P09', label: 'Pillar 09' },
        { value: 'P10', label: 'Pillar 10' },
        { value: 'P11', label: 'Pillar 11' },
        { value: 'P12', label: 'Pillar 12' },
        { value: 'P13', label: 'Pillar 13' },
        { value: 'P14', label: 'Pillar 14' },
        { value: 'P15', label: 'Pillar 15' },
        { value: 'P16', label: 'Pillar 16' },
        { value: 'P17', label: 'Pillar 17' },
        { value: 'P18', label: 'Pillar 18' },
        { value: 'P19', label: 'Pillar 19' },
        { value: 'P20', label: 'Pillar 20' },
        { value: 'P21', label: 'Pillar 21' },
        { value: 'P22', label: 'Pillar 22' },
        { value: 'P23', label: 'Pillar 23' },
        { value: 'P24', label: 'Pillar 24' },
        { value: 'S01', label: 'Suite 01' },
        { value: 'S02', label: 'Suite 02' }
    ]

    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);

    const handleStatusChange = option => {
        setErrors({...errors, message: '' });
        setValues({
            ...values,
            vaultStatusId: (option.value === null || option.value === 'undefined') ? 0 : option.value
        });
    }

    const handleBuildingChange = option => {
        setErrors({...errors, message: '' });
        setValues({
            ...values,
            buildingCode: (option.value === null || option.value === 'undefined') ? 0 : option.value
        });
    }

    const handlePillarChange = option => {
        setErrors({...errors, message: '' });
        setValues({
            ...values,
            pillarCode: (option.value === null || option.value === 'undefined') ? 0 : option.value
        });
    }

    const handleViewDetail = () => {
        const { summaryid, referenceNumber } = values;
        var path = `/pages/secured/user/admin-purchase-details/vault/${summaryid}`
        return <Navigate push to={path} />; 
    }

    const navigate = useNavigate();

    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const getVaultList = () => {
       
        setShowSpinner(true);

        var request = {
            VaultCode: values.vaultCode, 
            FloorPlanCode: values.floorPlanCode, 
            VaultStatusId: values.vaultStatusId,
            BuildingCode: values.buildingCode,
            PillarCode: values.pillarCode,
            OrderReferenceNumber: values.orderReferenceNumber
        };

        searchVaultList(request).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors("");
                setData(res.dataDictionary.data);
            } 
            else {
                if(res.status === 401) {
                    
                    localStorage.removeItem("fsb");
                    navigate('/pages/login');
                }

                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };

    const columns = [
        { dataField: "vaultCode", text: "Vault Code", sort: true, headerTitle: true },
        { dataField: "floorPlanCode", text: "Floor Code", sort: true, headerTitle: true },
        { dataField: "pillarName", text: "Pillar", sort: true, headerTitle: true },
        { dataField: "pillarLevel", text: "Level", sort: true, headerTitle: true },
        { dataField: "pillarColumn", text: "Column", sort: true, headerTitle: true },
        { dataField: "vaultStatusName", text: "Vault Status", sort: true, headerTitle: true },
        { dataField: "pillarDirection", text: "Direction", sort: true, headerTitle: true },
        { dataField: "referenceNum", text: "Order Reference Number", sort: true, headerTitle: true }
    ];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#A6B8A1',
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect) {
                setValues({
                    summaryid: row.orderSummaryId, 
                    referenceNumber: row.referenceNum
                })
            } 
        }
    };

    const renderShowsTotal = (start, to, total) => {
        return (
          <p style={ { color: 'blue' } }>
            Showing Records From { start } to { to } of { total }
          </p>
        );
    }

    const options = {
       defaultSortName: 'date',  // default sort column name
       defaultSortOrder: 'desc',  // default sort order
        page: 1,  // which page you want to show as default
        sizePerPageList: [ {
          text: '5', value: 5
        }, {
            text: '15', value: 15
        }, {
          text: '25', value: 25
        }, {
          text: 'All', value: (data.data) ? data.data.length : 9999999999
        } ], 
        sizePerPage: 10,  // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        paginationShowsTotal: renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        hideSizePerPage: true // You can hide the dropdown for sizePerPage
        // alwaysShowAllBtns: true // Always show next and previous button
        // withFirstAndLast: false > Hide the going to First and Last page button
    };

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            if(row.orderSummaryId != null && row.orderSummaryId != 'undefined') 
            {
                setValues({
                    summaryid: row.orderSummaryId, 
                    referenceNumber: row.referenceNum
                })
    
                setShowConfirmation(true);
                setModalMessage(`You have selected: ${row.referenceNum}. Do you want to proceed?`);
            } else {
                setShowConfirmation(true);
                setModalMessage(`No Purchase Details`);
            }
        }
    };

    const handleChange = name => event => {
        
        setValues({ ...values, [name]: event.target.value });
    };

    const handleSearchClick = () => {
        setData([]);
        getVaultList();
    }

    const handleCancel = () => {
        setShowConfirmation(false);
        setShowSpinner(false);
    };

    const handleSubmit = () => {
        setShowConfirmation(false);
        setModalMessage('');
        setViewDetail(true);
    };

    return (
        <CustomerLayout>
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <h2 className="ml-4">Vaults</h2>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md">
                            { showSpinner && <Spinner /> }
                            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="font-size-16">Ossuary - Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body  className="font-size-16">{modalMessage}</Modal.Body>
                                <Modal.Footer>
                                    {
                                        (values.summaryid != null && values.summaryid != 'undefined') &&
                                            <Button className="btn bg-color-primary" variant="secondary" onClick={() => { handleSubmit() }}>
                                                OK
                                            </Button>
                                    }
                                    <Button className="btn bg-color-default" variant="secondary" onClick={() => { handleCancel(); }}>
                                        CANCEL
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <div className="row mb-12">
                                <div className="col-md">
                                    <h3 id="warning-message" className="err-msg">{ errors.message }</h3>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div className="row mb-24">
                        <div className="col-md-4">
                            <label className="font-size-14"><b>VAULT CODE:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('vaultCode')} value={ values.vaultCode } />
                        </div>
                        <div className="col-md-4">
                            <label className="font-size-14"><b>STATUS:</b></label>
                            <Select 
                                className="font-size-14"
                                options={statuses} 
                                onChange={handleStatusChange} 
                                placeholder='Select All'
                                value={statuses.filter(function(option) {
                                    return option.value === values.vaultStatusId;
                                })}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="font-size-14"><b>Building:</b></label>
                            <Select 
                                className="font-size-14"
                                options={buildings} 
                                onChange={handleBuildingChange} 
                                placeholder='Select All'
                                value={buildings.filter(function(option) {
                                    return option.value === values.buildingCode;
                                })}
                            />
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md-4">
                            <label className="font-size-14"><b>Pillar:</b></label>
                            <Select 
                                className="font-size-14"
                                options={pillars} 
                                onChange={handlePillarChange} 
                                placeholder='Select All'
                                value={pillars.filter(function(option) {
                                    return option.value === values.pillarCode;
                                })}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="font-size-14"><b>ORDER REFERENCE NUMBER:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('orderReferenceNumber')} value={ values.orderReferenceNumber } />
                        </div>
                        <div className="col-md right">
                            <button onClick={handleSearchClick} className="btn btn--search mt-5">
                                <i className="fa fa-search mr-4"></i>
                                Search
                            </button>
                        </div>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md">
                            <BootstrapTable 
                                keyField='vaultCode' 
                                data={data} 
                                columns={columns} 
                                striped
                                hover
                                condensed
                                pagination={ PaginatorFactory(options) }
                                selectRow={ selectRow }
                                id="tbl"
                                noDataIndication={ 'No results found' }
                                rowEvents={ rowEvents }

                            /> 
                        </div>
                    </div>    
                </section>
            </main>
            { viewDetail && handleViewDetail() }
        </CustomerLayout>
    );
};

export default VaultList;
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import CustomerLayout from '../../../components/Layouts/CustomerLayout';

const ProfileUpdateConfirmation = () => {

    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };
    
    return (
        <CustomerLayout>
            <main>
                <div className="container center">
                    <div className="row mb-20">
                        <div className="col-md">
                            <div className="signup-confirmation vh-70">
                                <div className="wrapper-1">
                                    <div className="wrapper-2">
                                        <h1>Profile has been successfully Updated!</h1> 
                                        <br /><br />
                                        <Link className="go-home" to="/pages/secured/user/my-profile">Profile</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </CustomerLayout>
    );
};



export default ProfileUpdateConfirmation;
import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as moment from 'moment';

import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import Spinner from "../../../components/Spinner";
import { submitOrder, getOrderSummaryCustomerDetails } from "../../../apiCall/cart"
import { checkoutPage } from "../../../apiCall/mpgs";
import { removeVaultCart } from '../../../apiCall/vault'

import "react-datepicker/dist/react-datepicker.css";

const CustomerForm = () => {
    
    const [values, setValues] = useState({
        orderSummaryId: '',
        referenceNum: '',
        lastName: '',
        firstName: '',
        middleName: '',
        homeAddress: '',
        nationality: '',
        dtBirth: new Date(),
        gender: '',
        civilStatus: '',
        preferredMailingAddress: '',
        emailAddress: '',
        contactNumber: '',
        salesAgent: '',
        employerName: '',
        officeAddress: '',
        officePhoneNo: '',
        jobTitle: '',
        natureOfBusiness: '',
        loggedUserRole: '',
        accept: false,
        success: false, 
        error: false
    });

    const [errors, setErrors] = useState({
        lastName: '',
        firstName: '',
        middleName: '',
        homeAddress: '',
        nationality: '',
        dtBirth: '',
        gender: '',
        civilStatus: '',
        preferredMailingAddress: '',
        emailAddress: '',
        contactNumber: '',
        salesAgent: '',
        accept: '',
        messages: ''
    });

    const [showSpinner, setShowSpinner] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [status, setStatus] = useState(false);
    const [summaryId, setSummaryId] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    

    const genders = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
    ]

    const civilStatuses = [
        { value: 'S', label: 'Single' },
        { value: 'M', label: 'Married' },
        { value: 'D', label: 'Divorced' },
        { value: 'W', label: 'Widowed' }
    ]

    const params = useParams();

    useEffect(() => {
        goTop();
        setSummaryId(params.summaryId);
        getCustomer(params.summaryId);
        
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const getCustomer = (summaryId) => {
        setShowSpinner(true);
        getOrderSummaryCustomerDetails(summaryId).then(res => {
            if (res.isSuccess === true) { 
                setShowSpinner(false);
                var resOrderSummaryId = res.dataDictionary.orderSummaryId;
                var resReferenceNum = res.dataDictionary.referenceNum;
                var resLastName = res.dataDictionary.lastName;
                var resFirstName = res.dataDictionary.firstName;
                var resMiddleName = res.dataDictionary.middleName;
                var refHomeAddress = res.dataDictionary.homeAddress;
                var resNationality = res.dataDictionary.nationality;
                var resDtBirth = res.dataDictionary.dtBirth;
                var resGender = res.dataDictionary.gender;
                var resCivilStatus = res.dataDictionary.civilStatus;
                var resPreferredMailingAddress = res.dataDictionary.preferredMailingAddress;
                var resEmailAddress = res.dataDictionary.emailAddress;
                var resContactNumber = res.dataDictionary.contactNumber;
                var resSalesAgent = res.dataDictionary.salesAgent;
                var resEmployerName = res.dataDictionary.employerName;
                var resOfficeAddress = res.dataDictionary.officeAddress;
                var resOfficePhoneNo = res.dataDictionary.officePhoneNo;
                var resJobTitle = res.dataDictionary.jobTitle;
                var resNatureOfBusiness = res.dataDictionary.natureOfBusiness;
                var resLoggedUserRole = res.dataDictionary.loggedUserRole;
                
                setValues({
                    ...values,
                    orderSummaryId: (resOrderSummaryId  === null || resOrderSummaryId === 'undefined') ? summaryId : resOrderSummaryId,
                    referenceNum: (resReferenceNum  === null || resReferenceNum === 'undefined') ? '' : resReferenceNum,
                    lastName: (resLastName  === null || resLastName === 'undefined') ? '' : resLastName,
                    firstName: (resFirstName  === null || resFirstName === 'undefined') ? '' : resFirstName,
                    middleName: (resMiddleName  === null || resMiddleName === 'undefined') ? '' : resMiddleName,
                    homeAddress: (refHomeAddress  === null || refHomeAddress === 'undefined') ? '' : refHomeAddress,
                    nationality: (resNationality  === null || resNationality === 'undefined') ? '' : resNationality,
                    dtBirth: (resDtBirth  === null || resDtBirth === 'undefined') ? '' : new Date(moment(resDtBirth).format('L')),
                    gender: (resGender  === null || resGender === 'undefined') ? '' : resGender,
                    civilStatus: (resCivilStatus  === null || resCivilStatus === 'undefined') ? '' : resCivilStatus,
                    preferredMailingAddress: (resPreferredMailingAddress  === null || resPreferredMailingAddress === 'undefined') ? '' : resPreferredMailingAddress,
                    emailAddress: (resEmailAddress  === null || resEmailAddress === 'undefined') ? '' : resEmailAddress,
                    contactNumber: (resContactNumber  === null || resContactNumber === 'undefined') ? '' : resContactNumber,
                    salesAgent: (resSalesAgent  === null || resSalesAgent === 'undefined') ? '' : resSalesAgent,
                    employerName: (resEmployerName  === null || resEmployerName === 'undefined') ? '' : resEmployerName,
                    officeAddress: (resOfficeAddress  === null || resOfficeAddress === 'undefined') ? '' : resOfficeAddress,
                    officePhoneNo: (resOfficePhoneNo  === null || resOfficePhoneNo === 'undefined') ? '' : resOfficePhoneNo,
                    jobTitle: (resJobTitle  === null || resJobTitle === 'undefined') ? '' : resJobTitle,
                    natureOfBusiness: (resNatureOfBusiness  === null || resNatureOfBusiness === 'undefined') ? '' : resNatureOfBusiness,
                    loggedUserRole: (resLoggedUserRole  === null || resLoggedUserRole === 'undefined') ? '' : resLoggedUserRole
                });
            } 
            else {
                setShowSpinner(false);
                setErrors({...errors, message: res.message });
            }
        })
    };

    const handleChange = name => event => {
        
        setValues({ ...values, error: false, [name]: event.target.value });

        if(name === 'lastName') {
            setErrors({...errors, lastName: '', message: '' });
        }
        
        if(name === 'firstName') {
            setErrors({...errors, firstName: '', message: '' });
        } 

        if(name === 'middleName') {
            setErrors({...errors, middleName: '', message: '' });
        }

        if(name === 'homeAddress') {
            setErrors({...errors, homeAddress: '', message: '' });
        }

        if(name === 'nationality') {
            setErrors({...errors, nationality: '', message: '' });
        }

        if(name === 'dtBirth') {
            setErrors({...errors, dtBirth: '', message: '' });
        }

        if(name === 'gender') {
            setErrors({...errors, gender: '', message: '' });
        }

        if(name === 'civilStatus') {
            setErrors({...errors, civilStatus: '', message: '' });
        }

        if(name === 'preferredMailingAddress') {
            setErrors({...errors, preferredMailingAddress: '', message: '' });
        }

        if(name === 'emailAddress') {
            setErrors({...errors, emailAddress: '', message: '' });
        }
        
        if(name === 'contactNumber') {
            setErrors({...errors, contactNumber: '', message: '' });
        }
    };

    const handleGenderChange = option => {
        setErrors({...errors, gender: '', message: '' });
        setValues({
            ...values,
            gender: option.value
        });
    }

    const handleCivilStatusChange = option => {
        setErrors({...errors, civilStatus: '', message: '' });
        setValues({
            ...values,
            civilStatus: option.value
        });
    }

    const handleDtBirthchange = (date) => {
        setErrors({...errors, dtBirth: '', message: '' });
        setValues({ ...values, dtBirth: date });
    }

    const clickCancel = () => {

        removeVaultCart(summaryId).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setSubmit(false);
                setCancel(true);
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };

    const clickCheckout = () => {
        
        setShowSpinner(true);
        
        var request = {
            OrderSummaryId: summaryId, 
            referenceNum: values.referenceNum,
            LastName: values.lastName, 
            MiddleName: values.middleName, 
            FirstName: values.firstName, 
            HomeAddress: values.homeAddress,
            Nationality: values.nationality,
            DtBirth: values.dtBirth,
            Gender: values.gender,
            CivilStatus: values.civilStatus,
            PreferredMailingAddress: values.preferredMailingAddress,
            EmailAddress: values.emailAddress, 
            ContactNumber: values.contactNumber, 
            SalesAgent: values.salesAgent,
            EmployerName: values.employerName,
            OfficeAddress: values.officeAddress,
            OfficePhoneNo: values.officePhoneNo,
            JobTitle: values.jobTitle,
            NatureOfBusiness: values.natureOfBusiness,
            Accept: values.accept,
            WithPayment: true
        };

        submitOrder(request).then(res => {
            
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setResultMessage(res.message);
                setErrors({...errors, message: '' });
                
                if(res.dataDictionary !== "undefined" && res.dataDictionary !== null) {
                    
                    var merchantid = res.dataDictionary.merchantid &&  res.dataDictionary.merchantid;
                    var totalPrice = res.dataDictionary.totalPrice &&  res.dataDictionary.totalPrice;
                    var productName = res.dataDictionary.productName &&  res.dataDictionary.productName;
                    var orderid = res.dataDictionary.orderid &&  res.dataDictionary.orderid;
                    var checkoutSessionId = res.dataDictionary.checkoutSessionId &&  res.dataDictionary.checkoutSessionId;
                    var payment = res.dataDictionary.payment &&  res.dataDictionary.payment; 

                    if(payment === '1')
                    {
                        setStatus(false);
                        checkoutPage(merchantid, totalPrice, productName, orderid, checkoutSessionId);
                    }
                    else
                    {
                        setStatus(true);
                    }
                }
                else {
                    setErrors({
                        message: 'Missing Data Dictionary Data'
                    });
                }
            } 
            else {
                setShowSpinner(false);

                if(res.dataDictionary !== "undefined" && res.dataDictionary !== null) {
                    setErrors({
                        firstName: res.dataDictionary.firstName &&  res.dataDictionary.firstName,
                        middleName: res.dataDictionary.middleName &&  res.dataDictionary.middleName,
                        lastName: res.dataDictionary.lastName &&  res.dataDictionary.lastName,
                        homeAddress: res.dataDictionary.homeAddress &&  res.dataDictionary.homeAddress,
                        nationality: res.dataDictionary.nationality &&  res.dataDictionary.nationality,
                        dtBirth: res.dataDictionary.dtBirth &&  res.dataDictionary.dtBirth,
                        gender: res.dataDictionary.gender &&  res.dataDictionary.gender,
                        civilStatus: res.dataDictionary.civilStatus &&  res.dataDictionary.civilStatus,
                        preferredMailingAddress: res.dataDictionary.preferredMailingAddress &&  res.dataDictionary.preferredMailingAddress,
                        emailAddress: res.dataDictionary.email &&  res.dataDictionary.email,
                        contactNumber: res.dataDictionary.contactNumber &&  res.dataDictionary.contactNumber,
                        accept: res.dataDictionary.accept &&  res.dataDictionary.accept
                    });
                } else {
                    setErrors({
                        message: res.message
                    });
                }
            }
        })
    };

    const handleRedirect = () => {
        
        if (submit) {
            var path = `/pages/secured/my-purchase`
            return <Navigate push to={path} />;   
        }

        if (cancel) {
            return <Navigate to="/pages/secured/user/reservation" />;
        }

        if (status) {
            var path = `/pages/secured/user/reservation-result/${summaryId}`
            return <Navigate push to={path} />; 
        }
    }

    const handleToggle = (event) => {
        setErrors({ ...errors, accept: ''});
        setValues({ ...values, accept: event.target.checked});
    }

    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);
    

    return (
        <CustomerLayout>
            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="font-size-16">The Ossuary - Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body  className="font-size-16">{resultMessage}</Modal.Body>
                <Modal.Footer>
                    <Button className="btn bg-color-primary" variant="secondary" onClick={() => { setSubmit(true) }}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <div className="col-md">
                            { showSpinner && <Spinner /> }
                            <div className="row mb-12">
                                <div className="col-md">
                                    <h3 id="warning-message" className="err-msg">{ errors.message }</h3>
                                    
                                </div>
                            </div>
                            <div className="row mb-24">
                                <div className="col-md">
                                    <h2>CUSTOMER INFORMATION</h2>
                                </div>
                            </div>
                            <div className="row mb-18">
                                <div className="col-md">
                                    <label className="font-size-14"><b>FIRST NAME:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('firstName')} value={ values.firstName } />
                                    <div className="err-msg font-size-12" style={{ display: errors.firstName ? '' : 'none' }}>
                                        <span>{errors.firstName}</span>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <label className="font-size-14"><b>MIDDLE NAME:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('middleName')} value={ values.middleName } />
                                    <div className="err-msg font-size-12" style={{ display: errors.middleName ? '' : 'none' }}>
                                        <span>{errors.middleName}</span>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <label className="font-size-14"><b>LAST NAME:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('lastName')} value={ values.lastName } />
                                    <div className="err-msg font-size-12" style={{ display: errors.lastName ? '' : 'none' }}>
                                        <span>{errors.lastName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-18">
                                <div className="col-md">
                                    <label className="font-size-14"><b>HOME ADDRESS (No., Street, Bldg. Name, Town / City, County, Province / State, Country, Zip Code):</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('homeAddress')} value={ values.homeAddress } />
                                    <div className="err-msg font-size-12" style={{ display: errors.homeAddress ? '' : 'none' }}>
                                        <span>{errors.homeAddress}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-18">
                                <div className="col-md">
                                    <label className="font-size-14"><b>DATE OF BIRTH:</b></label>
                                    <DatePicker
                                        className="form-control font-size-14 height-36"
                                        selected={values.dtBirth}
                                        onChange={(date) => handleDtBirthchange(date) }
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    <div className="err-msg font-size-12" style={{ display: errors.dtBirth ? '' : 'none' }}>
                                        <span>{errors.dtBirth}</span>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <label className="font-size-14"><b>NATIONALITY:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('nationality')} value={ values.nationality } />
                                    <div className="err-msg font-size-12" style={{ display: errors.nationality ? '' : 'none' }}>
                                        <span>{errors.nationality}</span>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <label className="font-size-14"><b>SEX:</b></label>
                                    <Select 
                                        className="font-size-14"
                                        options={genders} 
                                        onChange={handleGenderChange} 
                                        value={genders.filter(function(option) {
                                            return option.value === values.gender;
                                        })}
                                    />
                                    <div className="err-msg font-size-12" style={{ display: errors.gender ? '' : 'none' }}>
                                        <span>{errors.gender}</span>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <label className="font-size-14"><b>CIVIL STATUS:</b></label>
                                    <Select 
                                        className="font-size-14"
                                        options={civilStatuses} 
                                        onChange={handleCivilStatusChange} 
                                        value={civilStatuses.filter(function(option) {
                                            return option.value === values.civilStatus;
                                        })}
                                    />
                                    <div className="err-msg font-size-12" style={{ display: errors.civilStatus ? '' : 'none' }}>
                                        <span>{errors.civilStatus}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-18">
                                <div className="col-md">
                                    <label className="font-size-14"><b>PREFERRED MAILING ADDRESS:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('preferredMailingAddress')} value={ values.preferredMailingAddress } />
                                    <div className="err-msg font-size-12" style={{ display: errors.preferredMailingAddress ? '' : 'none' }}>
                                        <span>{errors.preferredMailingAddress}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-26">
                                <div className="col-md">
                                    <label className="font-size-14"><b>MOBILE NO.:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('contactNumber')} value={ values.contactNumber } />
                                    <div className="err-msg font-size-12" style={{ display: errors.contactNumber ? '' : 'none' }}>
                                        <span>{errors.contactNumber}</span>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <label className="font-size-14"><b>EMAIL ADDRESS:</b></label>
                                    <input type="email" className="form-control font-size-14 height-36" onChange={handleChange('email')} value={ values.emailAddress } />
                                    <div className="err-msg font-size-12" style={{ display: errors.emailAddress ? '' : 'none' }}>
                                        <span>{errors.emailAddress}</span>
                                    </div>
                                </div>
                                { /*(values.loggedUserRole === 'admin') && <div className="col-md">
                                    <label className="font-size-14"><b>SALES AGENT:</b></label>
                                    <input type="email" className="form-control font-size-14 height-36" onChange={handleChange('agent')} value={ values.agent } />
                                    <div className="err-msg font-size-12" style={{ display: errors.email ? '' : 'none' }}>
                                        <span>{errors.agent}</span>
                                    </div>
                                </div> */}
                                <div className="col-md">
                                    <label className="font-size-14"><b>SALES AGENT:</b></label>
                                    <input type="email" className="form-control font-size-14 height-36" onChange={handleChange('salesAgent')} value={ values.salesAgent } />
                                    <div className="err-msg font-size-12" style={{ display: errors.salesAgent ? '' : 'none' }}>
                                        <span>{errors.salesAgent}</span>
                                    </div>
                                </div> 
                            </div>
                            <div className="row mb-30">
                                <div className="col-md">
                                    <h2>EMPLOYMENT INFORMATION</h2>
                                </div>
                            </div>
                            <div className="row mb-18">
                                <div className="col-md">
                                    <label className="font-size-14"><b>EMPLOYER/BUSINESS NAME:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('employerName')} value={ values.employerName } />
                                    <div className="err-msg" style={{ display: errors.employerName ? '' : 'none' }}>
                                        <span>{errors.employerName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-18">
                                <div className="col-md">
                                    <label className="font-size-14"><b>OFFICE ADDRESS (No., Street, Bldg. Name, Town / City, County, Province / State, Country, Zip Code):</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('officeAddress')} value={ values.officeAddress } />
                                    <div className="err-msg" style={{ display: errors.officeAddress ? '' : 'none' }}>
                                        <span>{errors.officeAddress}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-18">
                                <div className="col-md">
                                    <label className="font-size-14"><b>OFFICE PHONE NO.:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('officePhoneNo')} value={ values.officePhoneNo } />
                                    <div className="err-msg" style={{ display: errors.officePhoneNo ? '' : 'none' }}>
                                        <span>{errors.officePhoneNo}</span>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <label className="font-size-14"><b>JOB TITLE:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('jobTitle')} value={ values.jobTitle } />
                                    <div className="err-msg" style={{ display: errors.jobTitle ? '' : 'none' }}>
                                        <span>{errors.jobTitle}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-20">
                                <div className="col-md">
                                    <label className="font-size-14"><b>NATURE OF BUSINESS:</b></label>
                                    <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('natureOfBusiness')} value={ values.natureOfBusiness } />
                                    <div className="err-msg" style={{ display: errors.natureOfBusiness ? '' : 'none' }}>
                                        <span>{errors.natureOfBusiness}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-20">
                                <div className="col-md">
                                    <p className="font-size-14">
                                        <b>NOTE: </b> By submitting this form, the vault owner, as above indicated, confirms that all information that 
                                        he/she provided is true and correct, and verifiable by authentic records. Any false information shall be constituted as 
                                        fraudulent misrepresentation that may subject vault owner to civil, administrative or criminal actions. FSB Land Holdings Inc 
                                        undertakes to keep all information contained herein confidential and solely for its exclusive use and benefit.

                                    </p>
                                    <p className="font-size-14">
                                        Non-compliance with the foregoing schedule of payment amortizations on their respective due dates shall be assessed a 5% late penalty 
                                        charge on the overdue amount monthly, until the overdue amount, including penalties are paid in full. 
                                        Delayed payments for more than two (2) consecutive months from due date shall render the purchase as automatically 
                                        cancelled and/or terminated, and the payments so far made, shall be forfeited in favor of FSB Land Holdings.
                                    </p>
                                    <p className="font-size-14">
                                        A Sales Associate will contact the buyer and all pertinent documents will be sent upon confirmation of payment.
                                    </p>
                                    <p className="font-size-14">
                                        While information contained herein is based on present plans which have been prepared with 
                                        utmost care and is given in good faith, these may or may not reflect the most 
                                        current developments.
                                    </p>
                                </div>
                            </div>
                            <div className="row mb-20">
                                <div className="col-md pt-10">
                                    <input type="checkbox" id="rdoAcceptTerms" name="chk" className="rdoButton"  
                                        onChange={(event) => handleToggle(event)} 
                                        value={values.accept} 
                                        checked={values.accept}
                                    />
                                    <label className="rdoButton-label font-size-14">Accept terms and conditions.</label>
                                    <div className="err-msg font-size-12" style={{ display: errors.accept ? '' : 'none' }}>
                                        <span>{errors.accept}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-10">
                                <div className="col-md">
                                    <button onClick={clickCancel} className="btn btn--cancel mr-3">Cancel</button>
                                    <button onClick={clickCheckout} className="btn btn--primary">Submit &amp; Checkout</button>
                                </div>
                            </div>
                        </div>
                    </div>    
                </section>
            </main>
            { submit && handleRedirect() }
            { cancel && handleRedirect() }
            { status && handleRedirect() }
        </CustomerLayout>
    );
};

export default CustomerForm;
import React, { Fragment, useState, useEffect } from 'react';
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import { Navigate, useParams } from "react-router-dom";
import { getOrderPreview, updateOrderPaymentOption } from '../../../apiCall/cart'
import { removeVaultCart } from '../../../apiCall/vault'
import Spinner from '../../../components/Spinner'

import OrderPaymentOption20 from '../../../components/OrderPaymentOption20';
import OrderPaymentOption30 from '../../../components/OrderPaymentOption30';
import OrderPaymentOption50 from '../../../components/OrderPaymentOption50';
import OrderPaymentOptionFullPayment from '../../../components/OrderPaymentOptionFullPayment';
import OrderPaymentOptionReservation from '../../../components/OrderPaymentOptionReservation';

const OrderReview = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setErrors] = useState("");
    const [value, setValues] = useState([]);
    const [totalContractAmount, setTotalContractAmount] = useState('0.00');
    const [totalVATAmount, setTotalVATAmount] = useState('0.00');
    const [totalContractWithVATAmount, setTotalContractWithVATAmount] = useState('0.00');
    const [totalVaultNetAmount, setTotalVaultNetAmount] = useState('0.00');
    const [totalCheckoutAmount, setTotalCheckoutAmount] = useState('0.00');
    const [totalRemainingBalance, setTotalRemainingBalance] = useState('0.00');
    const [totalDiscountAmount, setTotalDiscountAmount] = useState('0.00');
    const [summaryId, setSummaryId] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    
    const [proceed, setProceed] = useState(false);
    const [modify, setModify] = useState(false);

    const params = useParams();

    useEffect(() => {
        init();
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const init = () => {
        const summaryId = params.summaryId;
        getOrderDetail(summaryId);
        setSummaryId(summaryId);
    }

    const getOrderDetail = (summaryId) => {
        setShowSpinner(true);
        getOrderPreview(summaryId).then(res => {
            if (res.isSuccess === true) {
                
                setShowSpinner(false);
                setErrors("");
                setValues(res.dataDictionary.data);
                setTotalContractAmount(res.dataDictionary.totalContractAmount);
                setTotalVATAmount(res.dataDictionary.totalVATAmount);
                setTotalContractWithVATAmount(res.dataDictionary.totalContractWithVATAmount);
                setTotalVaultNetAmount(res.dataDictionary.totalVaultNetAmount);
                setTotalCheckoutAmount(res.dataDictionary.totalCheckoutAmount);
                setTotalRemainingBalance(res.dataDictionary.totalRemainingBalance);
                setTotalDiscountAmount(res.dataDictionary.totalDiscountAmount);
                setPaymentOptions(res.dataDictionary.paymentOptions);
                
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };

    const formatCurrency = (value) => {
        return Number(value).toLocaleString("en-US", {
            style: "currency",
            currency: "PHP"
            
        });
    }

    const onClickModify = () => {
        
        removeVaultCart(summaryId).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setModify(true);
                setProceed(false);
            } 
            else {
                setModify(false);
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    }

    const onClickProceed = () => {
        setShowSpinner(false);
        setErrors("");
        setModify(false);
        setProceed(true);

    };

    const handleRedirect = () => {
        
        if (modify) {
            var path =localStorage.getItem("prev_vault_url")
            return <Navigate push to={path} />;   
        }

        if (proceed) {
            var link = `/pages/secured/user/form/${summaryId}`
            return <Navigate to={link} />;
        }
    }

    return (
        <CustomerLayout>
            { showSpinner && <Spinner /> }
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <div className="col-md">
                            <h3 className="err-msg">{ error }</h3>
                        </div>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md">
                            <table className="table table-success table-striped font-size-12">
                                <thead>
                                    <tr>
                                        <th scope="col">Seq</th>
                                        <th scope="col">Vault</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Vault Price</th>
                                        <th scope="col">Payment Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {value.map((product, idx) =>
                                        <tr key={idx}>
                                            <td className="td-width-150">
                                                {idx + 1}
                                            </td>
                                            <td className="td-width-150">
                                                {product.vaultCode}
                                                <h4>{product.vaultStatusName}</h4>
                                            </td>
                                            <td className="td-width-150">
                                                {product.building} <br /> {product.floor} <br /> {product.pillarName} {product.level}{product.column}
                                            </td>
                                            <td className="td-width-250">
                                                Vault Price: <span className="span-right">{formatCurrency(product.vaultSellingPrice)}</span> <br />
                                            </td>
                                            <td>
                                            {
                                                paymentOptions.map((paymentOption, id2) =>
                                                    <Fragment  key={id2}>
                                                        <OrderPaymentOptionFullPayment paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOption20 paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOption30 paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOption50 paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOptionReservation paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                    </Fragment>
                                                )
                                            }
                                            </td>
                                        </tr>
                                            
                                    )}
                                    <tr>
                                        <th colSpan="3" scope="col">Amount Due for Checkout</th>
                                        <th scope="col">
                                            <span className="span-right font-size-16">{formatCurrency(totalCheckoutAmount)}</span>
                                        </th>
                                        <th scope="col">&nbsp;</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mb-12 summary"> 
                        <div className="col-md">
                            <h2 className="notice">
                                Upon payment confirmation, a Sales Associate will contact the buyer for the documentation and installment payment process if applicable
                            </h2>
                        </div>
                        <div className="col-md right">
                            <button onClick={onClickModify} className="btn btn--cancel mr-4">Modify Orders</button>
                            <button onClick={onClickProceed} className="btn btn--primary  mr-4">Proceed</button>
                        </div>
                    </div>
                    
                </section>
            </main>
            { modify && handleRedirect() }
            { proceed && handleRedirect() }
        </CustomerLayout>
    );
};

export default OrderReview;
import React, { Fragment } from 'react';

const PaymentOption30 = (props) => {

    return(
        (props.paymentOption.isInstallment && props.paymentOption.paymentOptionId == 3 && props.product.paymentOptionId == 3) &&
            <Fragment>
                <div className="row mt-4">
                <div className="form-check-inline pl-4">
                        <input type="radio" 
                            className="rdoButton form-check-input mr-2" 
                            id={props.product.vaultId + "amort3"} 
                            name={props.product.vaultId + "amort"} 
                            value="1"
                            onChange={() => props.onClickReCompute(props.product.orderDetailId, props.paymentOption.paymentOptionId, props.product.orderSummaryId, 3)}
                            checked={props.product.monthlyPaymentOption === "3"}
                        />
                        <h3 className="form-check-label  mr-4">3</h3>

                        <input type="radio" 
                            className="rdoButton form-check-input" 
                            id={props.product.vaultId + "amort6"} 
                            name={props.product.vaultId + "amort"}
                            onChange={() => props.onClickReCompute(props.product.orderDetailId, props.paymentOption.paymentOptionId, props.product.orderSummaryId, 6)}
                            value="6"
                            checked={props.product.monthlyPaymentOption === "6"}
                        />
                        <h3 className="form-check-label  mr-4">6</h3>
                        
                        <input type="radio" 
                            className="rdoButton form-check-input" 
                            id={props.product.vaultId + "amort9"} 
                            name={props.product.vaultId + "amort"}
                            onChange={() => props.onClickReCompute(props.product.orderDetailId, props.paymentOption.paymentOptionId, props.product.orderSummaryId, 9)}
                            value="9"
                            checked={props.product.monthlyPaymentOption === "9"}
                        />
                        <h3 className="form-check-label  mr-4">9</h3>

                        <input type="radio" 
                            className="rdoButton form-check-input" 
                            id={props.product.vaultId + "amort12"} 
                            name={props.product.vaultId + "amort"}
                            onChange={() => props.onClickReCompute(props.product.orderDetailId, props.paymentOption.paymentOptionId, props.product.orderSummaryId, 12)}
                            value="12"
                            checked={props.product.monthlyPaymentOption === "12"}
                        />
                        <h3 className="form-check-label  mr-4">12</h3>

                        <input type="radio" 
                            className="rdoButton form-check-input" 
                            id={props.product.vaultId + "amort18"} 
                            name={props.product.vaultId + "amort"}
                            onChange={() => props.onClickReCompute(props.product.orderDetailId, props.paymentOption.paymentOptionId, props.product.orderSummaryId, 18)}
                            value="18"
                            checked={props.product.monthlyPaymentOption === "18"}
                        />
                        <h3 className="form-check-label  mr-4">18</h3>

                        <input type="radio" 
                            className="rdoButton form-check-input" 
                            id={props.product.vaultId + "amort24"} 
                            name={props.product.vaultId + "amort"}
                            onChange={() => props.onClickReCompute(props.product.orderDetailId, props.paymentOption.paymentOptionId, props.product.orderSummaryId, 24)}
                            value="24"
                            checked={props.product.monthlyPaymentOption === "24"}
                        />
                        <h3 className="form-check-label  mr-4">24</h3>

                        <input type="radio" 
                            className="rdoButton form-check-input" 
                            id={props.product.vaultId + "amort36"} 
                            name={props.product.vaultId + "amort"}
                            onChange={() => props.onClickReCompute(props.product.orderDetailId, props.paymentOption.paymentOptionId, props.product.orderSummaryId, 36)}
                            value="36"
                            checked={props.product.monthlyPaymentOption === "36"}
                        />
                        <h3 className="form-check-label  mr-4">36 Months</h3>
                        
                    </div>
                </div> 
                <div className="row pt-10 mb-10">
                    <div className="col-md-6">
                        <h4>Monthly amortization</h4>
                    </div>
                    <div className="form-check-inline pl-4">
                        <h3 className="form-check-label">{props.formatCurrency(props.product.monthlyAmortization)}</h3>
                    </div>
                </div>
            </Fragment>
    )
}

export default PaymentOption30;
import { API } from '../config'
import { isAuthenticated } from '../apiCall/auth'

export const getVault = (buildingCode, floorCode, rowNumber, pillar, pillarDirection) => {
    
    const token = isAuthenticated().token;

    return fetch(`${API}/vault/get-vault-list/${buildingCode}/${floorCode}/${rowNumber}/${pillar}/${pillarDirection}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const getVaultOssuary = (buildingCode, floorCode, rowNumber, pillar, pillarDirection) => {
    
    const token = isAuthenticated().token;

    return fetch(`${API}/vault/get-vault-list-ossuary/${buildingCode}/${floorCode}/${rowNumber}/${pillar}/${pillarDirection}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const addVaultCart = (selectedVault) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/vault/add-vault-cart`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(selectedVault)
    })
    .then(response => {
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const searchVaultList = (request) => {
    
    const token = isAuthenticated().token;

    return fetch(`${API}/vault/search-vault-list`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}

export const removeVaultCart = (summaryId) => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/vault/remove-vault-cart/${summaryId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        if(response.status !== 200) {
            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}